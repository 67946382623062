@font-face {
  font-family: "iconfont"; /* Project id 2972247 */
  src: url('iconfont.woff2?t=1638409955574') format('woff2'),
       url('iconfont.woff?t=1638409955574') format('woff'),
       url('iconfont.ttf?t=1638409955574') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xiazai:before {
  content: "\e664";
}

.icon-weixin:before {
  content: "\e600";
}

.icon-weibo:before {
  content: "\e65a";
}

.icon-douyin:before {
  content: "\e6fc";
}

.icon-yin-right:before {
  content: "\e63a";
}

.icon-yin-left:before {
  content: "\e63c";
}

.icon-home:before {
  content: "\e60a";
}

.icon-wangluo:before {
  content: "\e62e";
}

.icon-youxiang:before {
  content: "\e908";
}

.icon-chuanzhen:before {
  content: "\e627";
}

.icon-gongchang:before {
  content: "\e62a";
}

.icon-phone:before {
  content: "\e953";
}

.icon-sousuo:before {
  content: "\e78f";
}

.icon-guanbi:before {
  content: "\eca0";
}

.icon-xiala:before {
  content: "\e6b9";
}

