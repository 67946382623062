@import "../../assets/styles/utils";
.footer{
  background:#f2f2f2;
  @include res(padding-top,40px,14 / 40);
  @include res(padding-bottom,40px,14 / 40);
  .container{
    @include res(box-sizing,content-box,(1lg:border-box));
  }
  .footer-nav{
    display: flex;
    // align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include res(margin-top,45px,24 / 45);
    ul{
      @include res(width,33.33%,(xs:50%));
      @include res(margin-bottom,0px,(xs:20px));
      h1{
          @include res(font-size,18px,16 / 18);
          color: #777;
          font-weight: bolder;
          position: relative;
          @include res(margin-bottom,30px,20 / 30);
          &::after{
              content: '';
              display: block;
              @include res(width,38px, 30 / 38);
              height: 2px;
              background:#b4b4b4;
              position: absolute;
              @include res(bottom,-15px, -10 / -15);
              left:0;
          }
      }
      li{
        margin-top:6px;
        a{
          color:#979797;
          @include res(font-size,14px,13 / 14);
          transition: all 0.3s;
          &:hover{
            color: #00a2dc;
            padding-left:6px;
          }
        }
      }
      &:last-child{
        li{
          display: flex;
          @include res(font-size,14px,13 / 14);
          span{
            flex-shrink: 0;
          }
          a{
            &:hover{
              padding-left:0;
            }
          }
          &:last-child{
            display: flex;
            @include res(margin-top,20px,10 / 20);
            .icon{
              @include res(width,50px,30 / 50);
              @include res(height,50px,30 / 50);
              border: 2px solid #989898;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              @include res(margin-right,25px,15 / 25);
              position: relative;
              .ewm{
                @include res(width,150px,80 / 150);
                @include res(height,150px,80 / 150);
                position: absolute;
                @include res(bottom,54px,30 / 54);
                z-index: 9;
                display: none;
                img{
                  width: 100%;
                  height: 100%;
                  display: block;
                  margin: 0 auto;
                }
              }
              &:first-child{
                .ewm{
                  left: 0;
                }
              }
              &:nth-child(2){
                .ewm{
                  left:-100%;
                }
              }
              &:last-child{
                margin-right: 0;
                .ewm{
                  right: 0;
                }
              }
            }
            .iconfont{
              @include res(font-size,30px,20 / 30);
              color: #000;
            }
          }
        }
      }
    }
  }
}
.copyright{
  background: #c7c7c7;
  text-align: center;
  @include res(font-size,16px,14 / 16);
  @include res(padding-top,20px,15 / 20);
  @include res(padding-bottom,20px,15 / 20);
  font-weight: normal;
}
@media (max-width: 767px){
  .footer .footer-nav .last-ul{
    width: 100%;
  }
}
